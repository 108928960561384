import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IconButton, Select, Stack, Text } from "@chakra-ui/react";

export default function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear,
  monthDate,
}) {
  const currentYear = new Date().getFullYear();

  const onYearChange = (e) => {
    changeYear(e.target.value);
  };

  return (
    <Stack pb={1} direction="row" alignItems="center" textAlign="left" pl={4} pr={2}>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Previous Month"
        icon={<ChevronLeftIcon fontSize="14px" />}
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <Text color="gray.700" flex={1} fontSize="sm" fontWeight="medium">
        {new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date)}
      </Text>
      <Select
        size="sm"
        borderColor="gray.100"
        onChange={onYearChange}
        color="gray.700"
        fontSize="sm"
        fontWeight="medium"
      >
        {Array.from(new Array(120), (_, i) => currentYear - 120 + i + 1).map((year) => (
          <option key={year} value={year} selected={year === date.getFullYear()}>
            {year}
          </option>
        ))}
      </Select>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Next Month"
        icon={<ChevronRightIcon fontSize="14px" />}
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </Stack>
  );
}
