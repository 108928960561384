import { CalendarIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { forwardRef } from "react";

const CustomInput = forwardRef((props, ref) => {
  return (
    <InputGroup>
      <Input {...props} ref={ref} />
      <InputRightElement userSelect="none" pointerEvents="none">
        <CalendarIcon color="gray.500" />
      </InputRightElement>
    </InputGroup>
  );
});

export default CustomInput;
