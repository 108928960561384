import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IconButton, Stack, Text } from "@chakra-ui/react";

export default function CustomHeader({
  date,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <Stack minWidth={240} pb={1} direction="row" alignItems="center" textAlign="left" pl={4} pr={2}>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Previous Year"
        icon={<ChevronLeftIcon fontSize="14px" />}
        onClick={decreaseYear}
        disabled={prevMonthButtonDisabled}
      />
      <Text color="gray.700" flex={1} fontSize="sm" fontWeight="medium" textAlign="center">
        {new Intl.DateTimeFormat("en-US", {
          year: "numeric",
        }).format(date)}
      </Text>
      <IconButton
        borderRadius="full"
        size="sm"
        variant="ghost"
        aria-label="Next Year"
        icon={<ChevronRightIcon fontSize="14px" />}
        onClick={increaseYear}
        disabled={nextMonthButtonDisabled}
      />
    </Stack>
  );
}
