import { Alert, AlertDescription, AlertIcon, AlertTitle, Center } from "@chakra-ui/react";

export default function ResultsBox() {
  return (
    <Center>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="400"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Payment Complete
        </AlertTitle>
        <AlertDescription maxWidth="sm">Thank you! Your payment is successful.</AlertDescription>
      </Alert>
    </Center>
  );
}
