import { FormControl, FormErrorMessage, FormLabel, Input, Select, Stack } from "@chakra-ui/react";
import { US_STATES } from "@constants";
import cardValidator from "card-validator";
import { Field } from "formik";
import * as Yup from "yup";

export const PersonalDetailsSchema = Yup.object().shape({
  paymentMethod: Yup.object().shape({
    firstName: Yup.string().min(1, "Too Short!").max(64, "Too Long!").required("Required"),
    lastName: Yup.string().min(1, "Too Short!").max(64, "Too Long!").required("Required"),
    address1: Yup.string().min(1, "Too Short!").max(64, "Too Long!").required("Required"),
    address2: Yup.string().max(64, "Too Long!"),
    state: Yup.string().min(1, "Too Short!").max(64, "Too Long!").required("Required"),
    zipCode: Yup.string()
      .test(
        "test-postalcode", // this is used internally by yup
        "Zip code is invalid", // validation message
        (value) => cardValidator.postalCode(value).isValid,
      )
      .required("Required"),
  }),
});

export default function PersonalDetailsFields() {
  return (
    <>
      <Stack direction="column" spacing={6} w="100%" my={6}>
        <Stack direction={["column", "column", "row"]} spacing={[0, 0, 6]} w="100%">
          <Field name="paymentMethod.firstName">
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={
                  form.errors.paymentMethod?.firstName && form.touched.paymentMethod?.firstName
                }
              >
                <FormLabel>First Name</FormLabel>
                <Input {...field} placeholder="Enter first name..." />
                <FormErrorMessage>{form.errors.paymentMethod?.firstName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="paymentMethod.lastName">
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={
                  form.errors.paymentMethod?.lastName && form.touched.paymentMethod?.lastName
                }
                mt={[6, 6, 0]}
              >
                <FormLabel>Last Name</FormLabel>
                <Input {...field} placeholder="Enter last name..." />
                <FormErrorMessage>{form.errors.paymentMethod?.lastName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Field name="paymentMethod.address1">
          {({ field, form }) => (
            <FormControl
              isRequired
              isInvalid={
                form.errors.paymentMethod?.address1 && form.touched.paymentMethod?.address1
              }
            >
              <FormLabel>Address 1</FormLabel>
              <Input {...field} placeholder="Address 1" />
              <FormErrorMessage>{form.errors.paymentMethod?.address1}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="paymentMethod.address2">
          {({ field, form }) => (
            <FormControl
              isInvalid={
                form.errors.paymentMethod?.address2 && form.touched.paymentMethod?.address2
              }
              mt={[6, 6, 0]}
            >
              <FormLabel>Address 2</FormLabel>
              <Input {...field} placeholder="Address 2" />
              <FormErrorMessage>{form.errors.paymentMethod?.address2}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Stack>
      <Stack direction={["column", "column", "row"]} spacing={[0, 0, 6]} w="100%">
        <Field name="paymentMethod.state">
          {({ field, form }) => (
            <FormControl
              isRequired
              isInvalid={form.errors.paymentMethod?.state && form.touched.paymentMethod?.state}
            >
              <FormLabel>State</FormLabel>
              <Select {...field} placeholder="Select your state...">
                {Object.entries(US_STATES).map(([abbreviation, longName]) => (
                  <option key={abbreviation} value={abbreviation}>
                    {longName}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{form.errors.paymentMethod?.state}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="paymentMethod.zipCode">
          {({ field, form }) => (
            <FormControl
              isRequired
              isInvalid={form.errors.paymentMethod?.zipCode && form.touched.paymentMethod?.zipCode}
              mt={[6, 6, 0]}
            >
              <FormLabel>Zip Code</FormLabel>
              <Input {...field} placeholder="Enter zip code..." maxLength={10} />
              <FormErrorMessage>{form.errors.paymentMethod?.zipCode}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Stack>
    </>
  );
}
