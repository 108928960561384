import api, { transformRequest, transformResponse } from "services/api";

export const lookupApi = api.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
  endpoints: (build) => ({
    payAch: build.mutation({
      query: (payload) => ({
        url: `api/v1/core/consumers/pay/ach/`,
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
    payCard: build.mutation({
      query: (payload) => ({
        url: `api/v1/core/consumers/pay/card/`,
        method: "POST",
        body: transformRequest(payload),
      }),
      transformResponse: (response) => transformResponse(response),
    }),
  }),
});

export const { usePayAchMutation, usePayCardMutation } = lookupApi;
