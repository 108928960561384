import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <Container maxW="3xl">
      <Stack as={Box} textAlign="center" spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
        <Heading fontWeight={600} fontSize={{ base: "xl", sm: "2xl", md: "3xl" }} lineHeight="60%">
          You didn't break the
          <Text as="span" color="green.400">
            {" "}
            internet
          </Text>
        </Heading>
        <Text color="gray.600">But we can't find the page you're looking for.</Text>
        <Stack direction="column" spacing={3} align="center" alignSelf="center" position="relative">
          <Button
            colorScheme="green"
            bg="green.400"
            rounded="full"
            px={6}
            _hover={{
              bg: "green.500",
            }}
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
