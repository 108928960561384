import { useEffect, useRef } from "react";

// Copied from https://codesandbox.io/s/github/tanstack/table/tree/main/examples/react/row-selection?embed=1
export default function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest]);

  return <input type="checkbox" ref={ref} className={`${className} cursor-pointer`} {...rest} />;
}
