import { FormControl, FormErrorMessage, FormLabel, Input, Stack, VStack } from "@chakra-ui/react";
import cardValidator from "card-validator";
import { MonthPicker } from "components/datePicker";
import { Field } from "formik";
import * as Yup from "yup";

export const CreditCardFieldsSchema = Yup.object().shape({
  paymentMethod: Yup.object().shape({
    cardNumber: Yup.string().when("selectedPaymentMethod", {
      is: "credit_card",
      then: () =>
        Yup.string()
          .test(
            "test-ccnumber", // this is used internally by yup
            "Credit card number is invalid", // validation message
            (value) => cardValidator.number(value).isValid,
          )
          .required("Required"),
    }),
    cvv: Yup.string().when("selectedPaymentMethod", {
      is: "credit_card",
      then: () =>
        Yup.string()
          .test(
            "test-cvv", // this is used internally by yup
            "Security code is invalid", // validation message
            (value) => cardValidator.cvv(value).isValid,
          )
          .required("Required"),
    }),
    expirationDate: Yup.string().when("selectedPaymentMethod", {
      is: "credit_card",
      then: () =>
        Yup.string()
          .test(
            "test-expirationDate", // this is used internally by yup
            "Expiration date is invalid", // validation message
            (value) => cardValidator.expirationDate(value).isValid,
          )
          .required("Required"),
    }),
  }),
});

export default function CreditCardFields() {
  return (
    <VStack spacing={6} my={[6]}>
      <Field name="paymentMethod.cardNumber" required>
        {({ field, form }) => (
          <FormControl
            isRequired
            isInvalid={
              form.errors.paymentMethod?.cardNumber && form.touched.paymentMethod?.cardNumber
            }
          >
            <FormLabel>Credit Card Number</FormLabel>
            <Input {...field} placeholder="Enter credit card number..." maxLength={19} />
            <FormErrorMessage>{form.errors.paymentMethod?.cardNumber}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Stack direction={["column", "column", "row"]} spacing={[0, 0, 6]} w="100%">
        <Field name="paymentMethod.expirationDate">
          {({ field, form }) => (
            <FormControl
              isRequired
              isInvalid={
                form.errors.paymentMethod?.expirationDate &&
                form.touched.paymentMethod?.expirationDate
              }
            >
              <FormLabel>Expiration</FormLabel>
              <MonthPicker form={form} field={field} placeholderText="MM/YY" />
              <FormErrorMessage>{form.errors.paymentMethod?.expirationDate}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="paymentMethod.cvv">
          {({ field, form }) => (
            <FormControl
              isInvalid={form.errors.paymentMethod?.cvv && form.touched.paymentMethod?.cvv}
              mt={[6, 6, 0]}
            >
              <FormLabel>Security Code</FormLabel>
              <Input maxLength={4} minLength={3} {...field} placeholder="3 or 4 digit code" />
              <FormErrorMessage>{form.errors.paymentMethod?.cvv}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Stack>
    </VStack>
  );
}
