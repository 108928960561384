import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ErrorBoundaryPage } from "pages/errorBoundaryPage";
import { NotFoundPage } from "pages/notFoundPage";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import api from "services/api";
import ChakraProvider from "./chakraProvider";
import { PaymentPage } from "./pages/paymentPage";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

const router = createBrowserRouter([
  /* this path needs a special page or depricated
  {
    path: "/",
    Component: AboutAktosPage,
  },
  */
  {
    path: "/:slug",
    Component: PaymentPage,
    errorElement: <ErrorBoundaryPage />,
  },
  {
    path: "/404",
    Component: NotFoundPage,
    errorElement: <ErrorBoundaryPage />,
  },
  {
    path: "*",
    // any unmatched paths will fall back to 404
    Component: () => <Navigate to="/404" />,
    errorElement: <ErrorBoundaryPage />,
  },
]);

const reducers = combineReducers({
  // rtk-query part
  [api.reducerPath]: api.reducer,
});

export const rootReducer = (state, action) => {
  return reducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware]),
});

root.render(
  <StrictMode>
    <ChakraProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ChakraProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
