import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";

// Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {},
};

const theme = extendTheme({ colors });

export default function ({ children }) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
