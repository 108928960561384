import { css as chakraCSS, useTheme } from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import { useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/styles.css";
import CustomInput from "./customInput";
import MonthYearCustomHeader from "./monthYearCustomHeader";

function useDatePickerStyles() {
  const theme = useTheme();
  return useMemo(() => {
    const defaultStyles = {
      p: 2,
      bg: "white",
      border: "1px solid",
      borderColor: "gray.100",
      boxShadow: "sm",
      "& .react-datepicker": {
        "&__header": {
          bg: "none",
          borderBottom: "none",
        },
        "&__day-name": {
          color: "gray.500",
          fontWeight: "medium",
          w: 7,
        },
        "&__day": {
          lineHeight: "28px",
          color: "gray.700",
          w: 7,
          h: 7,
          borderRadius: "full",
          "&--disabled": {
            color: "gray.300",
          },
        },
        "&__day:not(.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day--disabled):hover":
          {
            bg: "white",
            boxShadow: "0 0 1px 1px rgba(0,0,0,0.2)",
          },
        "&__day--today": {
          bg: "gray.100",
          fontWeight: "400",
        },
        "&__day--selected, &__day--keyboard-selected": {
          bg: "blue.500",
          color: "white",
        },
        "&__triangle": {
          "&::before": {
            borderBottomColor: "gray.300 !important",
            transform: "translate(-20px, 0) !important",
          },
          "&::after": {
            borderBottomColor: "gray.50 !important",
            transform: "translate(-20px, 0) !important",
          },
        },
      },
    };
    return chakraCSS(defaultStyles)(theme);
  }, [theme]);
}

export default function CustomDatePicker({ field, form, placeholderText }) {
  const styles = useDatePickerStyles();

  const render = useCallback(
    ({ css }) => {
      return (
        <DatePicker
          {...field}
          calendarClassName={css(styles)}
          onChange={(value) => {
            form.setFieldValue(field.name, value);
          }}
          onSelect={(value) => {
            form.setFieldValue(field.name, value);
          }}
          customInput={<CustomInput />}
          selected={field.value}
          showYearDropdown
          dateFormat="MM/dd/yyyy"
          renderCustomHeader={MonthYearCustomHeader}
          placeholderText={placeholderText}
          maxDate={new Date()}
        />
      );
    },
    [field, form, placeholderText, styles],
  );

  return <ClassNames>{render}</ClassNames>;
}
