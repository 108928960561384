import { createIcon } from "@chakra-ui/icons";

// PaymentPortalText
export default createIcon({
  displayName: "PaymentPortalText",
  viewBox: "250 0 1877.96 260.52",
  path: (
    <g id="Layer_4" data-name="Layer 4">
      <path d="M312.6,55.53h55.18q26.27,0,39.41,14.35,13.35,14.15,13.34,35,0,20.61-13.74,34.76Q393,153.35,365.56,153.35h-23v51.54H312.6Zm29.92,70.74h24.05q11.12,0,17.28-6.17A21.21,21.21,0,0,0,390,104.44a20.77,20.77,0,0,0-6-15.16q-6-6.06-15.87-6.06H342.52Z" />
      <path d="M515.93,107.87V94.74H543V204.89H515.93V192.16a45.89,45.89,0,0,1-35.17,15.36q-23.85,0-39.21-16.58t-15.36-41.63q0-24.87,15.56-41,15.57-16.38,39-16.37Q501.37,91.91,515.93,107.87Zm.4,41.84a31.72,31.72,0,0,0-8.89-22.43q-8.7-9.3-22-9.3-13.55,0-22.23,9.3a33.86,33.86,0,0,0,.2,45.07q8.69,9.3,22,9.3t22-9.3A32,32,0,0,0,516.33,149.71Z" />
      <path d="M618.8,166.49l25.07-71.75H675L630.73,210.35q-8.09,21.21-19.3,31.32T584,251.78q-12.54,0-21.22-5.46l4.85-23.85a32.35,32.35,0,0,0,14.15,3.64q13.13,0,21.42-17.38l2.22-5L557.36,94.74H590.3Z" />
      <path d="M822.94,91.71q19.8,0,32.13,12.33,12.73,12.33,12.74,34.36v66.49h-28.5V143.65q0-12.33-6-19T817.28,118q-10.92,0-17.59,7.17T793,144.46v60.43h-28.5v-61q0-12.12-6-19T742.29,118A22.59,22.59,0,0,0,725,125.26q-6.76,7.28-6.77,19.4v60.23h-28.5V94.74h27.9V110.5a34.39,34.39,0,0,1,13.74-13.84,40.73,40.73,0,0,1,39.11.1,30.93,30.93,0,0,1,13,14.35A41.33,41.33,0,0,1,800.1,96.86,51.22,51.22,0,0,1,822.94,91.71Z" />
      <path d="M995.14,147.29a95.58,95.58,0,0,1-1,12.53H911.46q6.06,23.44,32.14,23.44a62.07,62.07,0,0,0,35.57-11.11l11.32,20q-21,15.15-48.1,15.15-28.9,0-44.47-16.77Q882.56,174,882.56,149.71q0-25.26,16-41.43,16-16.38,41.43-16.37,24,0,39.62,15T995.14,147.29ZM940,115.56a28.07,28.07,0,0,0-18.29,6.16q-7.57,6.16-10.21,16.87h56q-2-10.69-9.3-16.87T940,115.56Z" />
      <path d="M1039.2,204.89h-28.5V94.74h27.89V110.1a32.76,32.76,0,0,1,13.75-13.34,42.81,42.81,0,0,1,20.81-5.05q18,0,30.52,11.92t12.53,34v67.3h-28.5V145.27q0-12.93-6.26-20.12t-17-7.17q-11.32,0-18.29,7.78t-7,20.11Z" />
      <path d="M1210.59,176l8.48,22.43q-13.74,9.11-32.53,9.1-19,0-28.71-11t-9.7-32.44V118.59h-18V94.74h18.18v-39h28.3v39H1213v23.85h-36.38v43.65q0,19.41,15.56,19.41Q1200.68,181.65,1210.59,176Z" />
      <path d="M1286.18,55.53h55.18q26.27,0,39.4,14.35,13.35,14.15,13.35,35,0,20.61-13.75,34.76-13.74,13.74-41.23,13.74h-23v51.54h-29.91Zm29.91,70.74h24.05q11.11,0,17.28-6.17a21.21,21.21,0,0,0,6.16-15.66,20.73,20.73,0,0,0-6-15.16q-6-6.06-15.86-6.06h-25.67Z" />
      <path d="M1399.76,149.71q0-24.45,16.57-41,16.79-17,42.45-17t42.44,17q16.58,16.57,16.57,41,0,24.66-16.57,41.23-16.77,16.78-42.44,16.78t-42.45-16.78Q1399.76,174.38,1399.76,149.71ZM1437,172.15a30.51,30.51,0,0,0,43.45,0,31.74,31.74,0,0,0,8.89-22.44,31,31,0,0,0-8.89-22.23,29.08,29.08,0,0,0-21.62-9.09q-13.14,0-21.83,9.09-8.7,8.89-8.69,22.23A31.66,31.66,0,0,0,1437,172.15Z" />
      <path d="M1562.26,204.89h-28.5V94.74h27.29v14.75q9.9-17.77,30.31-17.78a38.69,38.69,0,0,1,13.34,2.42l-2.42,27.28a45.83,45.83,0,0,0-13.14-2.22q-12.54,0-19.7,8.39t-7.18,25Z" />
      <path d="M1696.46,176l8.49,22.43q-13.76,9.11-32.54,9.1-19,0-28.7-11t-9.7-32.44V118.59h-18V94.74h18.19v-39h28.3v39h36.38v23.85h-36.38v43.65q0,19.41,15.56,19.41Q1686.56,181.65,1696.46,176Z" />
      <path d="M1796.71,107.87V94.74h27.08V204.89h-27.08V192.16q-14.14,15.36-35.17,15.36-23.85,0-39.21-16.58T1707,149.31q0-24.87,15.56-41,15.57-16.38,39-16.37Q1782.15,91.91,1796.71,107.87Zm.41,41.84a31.73,31.73,0,0,0-8.9-22.43q-8.68-9.3-22-9.3-13.55,0-22.23,9.3a33.86,33.86,0,0,0,.2,45.07q8.68,9.3,22,9.3t22-9.3A32,32,0,0,0,1797.12,149.71Z" />
      <path d="M1878,51.28V204.89h-28.5V51.28Z" />
    </g>
  ),
});
