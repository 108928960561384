import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IndeterminateCheckbox } from "components/intermediateCheckbox";
import { Radio } from "components/radio";
import { Table } from "components/table";
import currency from "currency.js";
import AchFields, { AchFieldsSchema } from "features/paymentDetailsForm/fields/achFields";
import {
  AmountFields,
  getAmountFieldsSchema,
} from "features/paymentDetailsForm/fields/amountFields";
import CreditCardFields, {
  CreditCardFieldsSchema,
} from "features/paymentDetailsForm/fields/creditCardFields";
import PersonalDetailsFields, {
  PersonalDetailsSchema,
} from "features/paymentDetailsForm/fields/personalDetailsFields";
import { Field, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { LiaCreditCardSolid, LiaMoneyCheckAltSolid } from "react-icons/lia";
import { formatCurrency } from "utils/currencyUtils";
import * as Yup from "yup";

const paymentMethodOptions = [
  {
    label: (
      <HStack>
        <Icon as={LiaCreditCardSolid} boxSize={4} />
        <Text>Credit Card</Text>
      </HStack>
    ),
    value: "credit_card",
  },
  {
    label: (
      <HStack>
        <Icon as={LiaMoneyCheckAltSolid} boxSize={4} />
        <Text>ACH</Text>
      </HStack>
    ),
    value: "ach",
  },
];

const paymentColumns = [
  {
    id: "select",
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  {
    header: "Account #",
    accessorKey: "externalId",
  },
  {
    header: "Account Balance",
    accessorKey: "debtAmount",
    cell: (info) => formatCurrency(info.getValue()),
  },
];

export default function PaymentDetailsForm({ userData, onSubmit, isLoading }) {
  const payableAccounts = useMemo(
    () =>
      userData?.accounts
        ?.filter((account) => !(account.isSettled || account.isClosed))
        .reduce((acc, debtorAccount) => {
          const debtAmount = currency(debtorAccount.totalBalance, { precision: 4 }).value;
          if (debtAmount > 0) {
            return [
              ...acc,
              {
                externalId: debtorAccount.externalId,
                debtAmount,
              },
            ];
          }
          return [...acc];
        }, []),
    [userData],
  );
  const [rowSelection, setRowSelection] = useState(
    payableAccounts.reduce((acc, debtorAccount) => {
      acc[debtorAccount.externalId] = true;
      return acc;
    }, {}),
  );
  const selectedAccounts = useMemo(() => {
    return payableAccounts.filter((account) => rowSelection[account.externalId]);
  }, [payableAccounts, rowSelection]);
  const debtTotal = useMemo(
    () =>
      currency(
        selectedAccounts.reduce((accumulator, account) => {
          return currency(accumulator, { precision: 4 }).add(account?.debtAmount ?? 0);
        }, 0),
        { precision: 2 },
      ).value,
    [selectedAccounts],
  );
  const PaymentDetailsSchema = Yup.object()
    .shape({
      selectedPaymentMethod: Yup.string().oneOf(["credit_card", "ach"]).required(),
      paymentMethod: Yup.object().shape({
        emails: Yup.string().email(),
      }),
    })
    // We add all the schemas from the other field groups
    .concat(getAmountFieldsSchema({ totalAmount: debtTotal }))
    .concat(PersonalDetailsSchema)
    .concat(CreditCardFieldsSchema)
    .concat(AchFieldsSchema);

  const handleOnSubmit = (values, formikHelpers) =>
    onSubmit(
      {
        ...values,
        externalAccountIds: selectedAccounts.map((account) => account.externalId),
      },
      formikHelpers,
    );

  return (
    <Formik
      initialValues={{
        selectedPaymentMethod: "credit_card",
        selectedPaymentAmount: "full_amount",
        paymentMethod: {
          state: "AL",
        },
        paymentIntent: {
          totalAmount: debtTotal,
        },
      }}
      onSubmit={handleOnSubmit}
      validationSchema={PaymentDetailsSchema}
    >
      {(props) => (
        <Form>
          <VStack spacing={1}>
            <Heading size="md" fontWeight="medium" mb={2}>
              Select Accounts for Payment
            </Heading>
            <Table
              columns={paymentColumns}
              data={payableAccounts}
              enableMultiRowSelection
              rowSelection={rowSelection}
              onRowSelectionChange={setRowSelection}
              getRowId={(row) => row.externalId}
            />
          </VStack>
          <AmountFields totalAmount={debtTotal} />
          <Spacer my={6} />
          <Field name="selectedPaymentMethod">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.selectedPaymentMethod && form.touched.selectedPaymentMethod}
              >
                <FormLabel>Payment Method</FormLabel>
                <Radio form={form} field={field} options={paymentMethodOptions} />
              </FormControl>
            )}
          </Field>
          <Spacer my={12} />
          <Heading size="sm">Payment Information</Heading>
          {props.values.selectedPaymentMethod === "credit_card" && <CreditCardFields />}
          {props.values.selectedPaymentMethod === "ach" && <AchFields />}
          <Heading size="sm">Billing Details</Heading>
          <VStack>
            <PersonalDetailsFields />
            <Field name="paymentMethod.emails">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.paymentMethod?.emails && form.touched.paymentMethod?.emails
                  }
                >
                  <Spacer my={6} />
                  <FormLabel>Email Address</FormLabel>
                  <Input {...field} type="email" />
                  <FormErrorMessage>{form.errors.paymentMethod?.emails}</FormErrorMessage>
                  <Spacer my={6} />
                  <FormHelperText textAlign="justify">
                    By entering your email address in our payment portal, you acknowledge and
                    consent to communications via email. These communications may include
                    transactional information, updates, and other relevant notifications. Your email
                    will not be shared with third parties without your explicit permission.
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            {props.status && (
              <Alert status="error" my={[2, 2, 4]}>
                <AlertIcon />
                {props.status}
              </Alert>
            )}
            <Button
              colorScheme="blue"
              type="submit"
              w="180px"
              h="50px"
              mb={[8, 8, 12]}
              isLoading={props.isSubmitting || isLoading}
              isDisabled={selectedAccounts.length === 0}
            >
              Pay
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}
