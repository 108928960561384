/**
 * Converts a string from camelCase to snake_case.
 * @param {string} str - The input string in camelCase format.
 * @returns {string} - The string converted to snake_case format.
 */
export function camelToSnakeCase(str) {
  return str.replace(/([a-z])([A-Z0-9])/g, (match, p1, p2) => `${p1}_${p2}`).toLowerCase();
}

/**
 * Converts a snake_case string to camelCase.
 * @param {string} str - The snake_case string to convert.
 * @returns {string} - The equivalent camelCase string.
 */
export function snakeToCamelCase(str) {
  return str.replace(/_([a-z0-9])/g, (match, p1) => p1.toUpperCase());
}

/**
 * Recursively renames object keys using a given renaming function.
 * @param {Object} obj - The object to rename keys for.
 * @param {Function} renameFunc - The function to use for renaming keys.
 * @returns {Object} - A new object with the renamed keys.
 */
export function deepRename(obj, renameFunc) {
  // We don't care about anything that isn't an object
  // Note: `typeof []` => "object" (!)
  // And even worse: `typeof null` => "object" (!!)
  if (typeof obj !== "object" || obj === null) return obj;

  // Arrays: just map each item over `deepRename`
  if (Array.isArray(obj)) {
    return obj.map((subObj) => deepRename(subObj, renameFunc));
  }

  // All that's left are non-null, non-array, plain old objects
  const newObj = {};
  Object.keys(obj).forEach((property) => {
    newObj[renameFunc(property)] = deepRename(obj[property], renameFunc);
  });
  return newObj;
}

// Adds key to arrays. Prevents key-related issues in the console
export function populateKey(data, getter = (i) => i.id) {
  const populateObject = (object) => {
    const key = getter(object);
    const keyObject = key ? { key } : {};
    return {
      ...object,
      ...keyObject,
    };
  };

  if (data === undefined) return;
  if (data.constructor === Array) {
    return data.map(populateObject);
  }
  if (typeof data === "object" && !!data) {
    return populateObject(data);
  }
  return data;
}
