import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { camelToSnakeCase, deepRename, populateKey, snakeToCamelCase } from "utils/apiUtils";
import { v4 as uuidv4 } from "uuid";

const API_REDUCER_KEY = "api";

function getBaseQuery() {
  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASEURL,
    prepareHeaders: (headers) => {
      // Generate a UUID for each request for tracking purposes.
      headers.set("x-request-id", uuidv4());

      return headers;
    },
  });
}

export function transformResponse(response, customGetter) {
  return populateKey(
    deepRename(response.results ?? response.result, snakeToCamelCase),
    customGetter,
  );
}

export function transformRequest(request) {
  return deepRename(request, camelToSnakeCase);
}

const api = createApi({
  reducerPath: API_REDUCER_KEY,
  baseQuery: getBaseQuery(),
  endpoints: () => ({}),
  // 60 seconds TTL cache for the same query/data
  refetchOnMountOrArgChange: 60,
});

export default api;
