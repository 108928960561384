import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  const { children } = props;

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="none"
        bg="gray.50"
        _checked={{
          bg: "blue.600",
          color: "white",
          borderColor: "blue.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        width={["100%", "100%", "auto"]}
      >
        {children}
      </Box>
    </Box>
  );
}

export default function Radio({ form, field, options, onChangeCallback = undefined }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: field.name,
    onChange: (value) => {
      form.setFieldValue(field.name, value);
      if (onChangeCallback) {
        onChangeCallback(value);
      }
    },
    value: field.value,
  });

  const group = getRootProps();

  return (
    <HStack {...group} align="stretch" gap={6}>
      {options.map(({ value, label }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {label}
          </RadioCard>
        );
      })}
    </HStack>
  );
}
