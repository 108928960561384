import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Radio } from "components/radio";
import { Field } from "formik";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import * as usBankAccountValidator from "us-bank-account-validator";
import * as Yup from "yup";

export const AchFieldsSchema = Yup.object().shape({
  paymentMethod: Yup.object().shape({
    bankRoutingNumber: Yup.string().when("selectedPaymentMethod", {
      is: "ach",
      then: () =>
        Yup.string()
          .test(
            "test-bankroutingnumber", // this is used internally by yup
            "Routing number is invalid", // validation message
            (value) => usBankAccountValidator.routingNumber(value).isValid,
          )
          .required("Required"),
    }),
    bankAccountNumber: Yup.string().when("selectedPaymentMethod", {
      is: "ach",
      then: () =>
        Yup.string()
          .test(
            "test-bankaccountnumber", // this is used internally by yup
            "Account number is invalid", // validation message
            (value) => usBankAccountValidator.accountNumber(value).isValid,
          )
          .required("Required"),
    }),
    bankAccountName: Yup.string().when("selectedPaymentMethod", {
      is: "ach",
      then: () => Yup.string().min(2, "Too Short!").max(128, "Too Long!").required("Required"),
    }),
    bankAccountType: Yup.string().when("selectedPaymentMethod", {
      is: "ach",
      then: () => Yup.mixed().oneOf(["checking", "savings"]).required("Required"),
    }),
  }),
});

const options = [
  {
    value: "checking",
    label: (
      <HStack>
        <Icon as={LiaMoneyCheckSolid} boxSize={4} />
        <Text>Checking</Text>
      </HStack>
    ),
  },
  {
    value: "savings",
    label: (
      <HStack>
        <Icon as={LiaMoneyCheckSolid} boxSize={4} />
        <Text>Savings</Text>
      </HStack>
    ),
  },
];

export default function AchFields() {
  return (
    <VStack spacing={6} my={[6]}>
      <Field name="paymentMethod.bankRoutingNumber">
        {({ field, form }) => (
          <FormControl
            isRequired
            isInvalid={
              form.errors.paymentMethod?.bankRoutingNumber &&
              form.touched.paymentMethod?.bankRoutingNumber
            }
          >
            <FormLabel>Routing Number</FormLabel>
            <Input {...field} placeholder="Type routing number..." />
            <FormErrorMessage>{form.errors.paymentMethod?.bankRoutingNumber}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="paymentMethod.bankAccountType">
        {({ field, form }) => (
          <FormControl
            isRequired
            isInvalid={
              form.errors.paymentMethod?.bankAccountType &&
              form.touched.paymentMethod?.bankAccountType
            }
          >
            <FormLabel>Bank Account Type</FormLabel>
            <Radio form={form} field={field} options={options} />
            <FormErrorMessage>{form.errors.paymentMethod?.bankAccountType}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="paymentMethod.bankAccountNumber">
        {({ field, form }) => (
          <FormControl
            isRequired
            isInvalid={
              form.errors.paymentMethod?.bankAccountNumber &&
              form.touched.paymentMethod?.bankAccountNumber
            }
          >
            <FormLabel>Account Number</FormLabel>
            <Input {...field} placeholder="Type account number..." />
            <FormErrorMessage>{form.errors.paymentMethod?.bankAccountNumber}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="paymentMethod.bankAccountName">
        {({ field, form }) => (
          <FormControl
            isRequired
            isInvalid={
              form.errors.paymentMethod?.bankAccountName &&
              form.touched.paymentMethod?.bankAccountName
            }
          >
            <FormLabel>Account Name</FormLabel>
            <Input {...field} placeholder="Type account name..." />
            <FormErrorMessage>{form.errors.paymentMethod?.bankAccountName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </VStack>
  );
}
