import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { COLLECTION_DEBT_TYPES } from "@constants";
import { DatePicker } from "components/datePicker";
import { Radio } from "components/radio";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

export default function LookupForm({ onSubmit, isLoading }) {
  const lookupFormSchema = Yup.object().shape({
    collectionDebtType: Yup.string()
      .oneOf(COLLECTION_DEBT_TYPES.map((x) => x.value))
      .required("Required"),
    firstName: Yup.string()
      .min(1, "Too Short!")
      .max(64, "Too Long!")
      .when("collectionDebtType", {
        is: (value) => value === "consumer",
        then: (schema) => schema.required("Required"),
      }),
    lastName: Yup.string()
      .min(1, "Too Short!")
      .max(64, "Too Long!")
      .when("collectionDebtType", {
        is: (value) => value === "consumer",
        then: (schema) => schema.required("Required"),
      }),
    externalAccountId: Yup.string()
      .min(1, "Too Short!")
      .max(64, "Too Long!")
      .when("collectionDebtType", {
        is: (value) => value === "consumer",
        then: (schema) => schema.required("Required"),
      }),
    dob: Yup.date().when("collectionDebtType", {
      is: (value) => value === "consumer",
      then: (schema) => schema.required("Required"),
    }),
    ssnLast4Digits: Yup.number().min(0, "Too Short!").max(9999, "Too Long!"),
    companyName: Yup.string()
      .min(1, "Too Short!")
      .max(64, "Too Long!")
      .when("collectionDebtType", {
        is: (value) => value === "commercial",
        then: (schema) => schema.required("Required"),
      }),
    companyEin: Yup.string().min(1, "Too Short!").max(64, "Too Long!"),
  });
  const collectionDebtTypeOptions = COLLECTION_DEBT_TYPES.map((debtType) => ({
    label: (
      <HStack>
        <Text>{debtType.label}</Text>
      </HStack>
    ),
    value: debtType.value,
  }));

  return (
    <Formik
      initialValues={{
        collectionDebtType: COLLECTION_DEBT_TYPES[0].value,
        firstName: "",
        lastName: "",
        externalAccountId: "",
        dob: undefined,
        ssnLast4Digits: undefined,
        companyName: "",
        companyEin: "",
      }}
      onSubmit={onSubmit}
      validationSchema={lookupFormSchema}
    >
      {(props) => (
        <Form>
          <VStack spacing={6}>
            <Field name="externalAccountId" required>
              {({ field, form }) => (
                <FormControl
                  isRequired
                  isInvalid={form.errors.externalAccountId && form.touched.externalAccountId}
                >
                  <FormLabel>Account Number</FormLabel>
                  <Input {...field} placeholder="Enter your account number..." />
                  <FormErrorMessage>{form.errors.externalAccountId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="collectionDebtType" required>
              {({ field, form }) => (
                <FormControl
                  isRequired
                  isInvalid={form.errors.collectionDebtType && form.touched.collectionDebtType}
                >
                  <FormLabel>Debt Type</FormLabel>
                  <Radio form={form} field={field} options={collectionDebtTypeOptions} />
                  <FormErrorMessage>{form.errors.collectionDebtType}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {props.values.collectionDebtType === "consumer" && (
              <>
                <Stack direction={["column", "column", "row"]} spacing={[0, 0, 6]} w="100%">
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.firstName && form.touched.firstName}
                      >
                        <FormLabel>First Name</FormLabel>
                        <Input {...field} placeholder="Enter first name..." />
                        <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.lastName && form.touched.lastName}
                        mt={[6, 6, 0]}
                      >
                        <FormLabel>Last Name</FormLabel>
                        <Input {...field} placeholder="Enter last name..." />
                        <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Field name="dob">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.dob && form.touched.dob} isRequired>
                      <FormLabel>Date of Birth</FormLabel>
                      <DatePicker form={form} field={field} placeholderText="MM/DD/YYYY" />
                      <FormErrorMessage>{form.errors.dob}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="ssnLast4Digits">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.ssnLast4Digits && form.touched.ssnLast4Digits}
                    >
                      <FormLabel>SSN Last 4 Digits</FormLabel>
                      <Input
                        {...field}
                        maxLength={4}
                        placeholder="Enter last 4 digits of your SSN..."
                      />
                      <FormErrorMessage>{form.errors.ssnLast4Digits}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
            )}
            {props.values.collectionDebtType === "commercial" && (
              <>
                <Field name="companyName">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.companyName && form.touched.companyName}
                    >
                      <FormLabel>Company Name</FormLabel>
                      <Input {...field} placeholder="Enter company name..." />
                      <FormErrorMessage>{form.errors.companyName}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="companyEin">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.companyEin && form.touched.companyEin}
                      mt={[6, 6, 0]}
                    >
                      <FormLabel>Company EIN</FormLabel>
                      <Input {...field} placeholder="Enter company EIN..." />
                      <FormErrorMessage>{form.errors.companyEin}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
            )}
            {props.status && (
              <Alert status="error">
                <AlertIcon />
                {props.status}
              </Alert>
            )}
            <Button
              isLoading={props.isSubmitting || isLoading}
              colorScheme="blue"
              type="submit"
              w="180px"
              h="50px"
              mb={[8, 8, 12]}
            >
              Next
            </Button>
            <Text fontSize="m" fontStyle="italic">
              This is an attempt to collect a debt, and any information obtained will be used for
              that purpose. This communication is from a debt collector.
            </Text>
          </VStack>
        </Form>
      )}
    </Formik>
  );
}
