import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useReactTable, flexRender, getCoreRowModel } from "@tanstack/react-table";

// Copied from chakra-ui: https://chakra-ui.com/getting-started/with-react-table
export default function DataTable({
  data,
  columns,
  rowSelection,
  getSelectedRowModel,
  getRowId,
  onRowSelectionChange,
  enableMultiRowSelection = false,
}) {
  const table = useReactTable({
    columns,
    data,
    enableMultiRowSelection,
    onRowSelectionChange,
    getRowId,
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getSelectedRowModel,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const { meta } = header.column.columnDef;
              return (
                <Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  isNumeric={meta?.isNumeric}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              const { meta } = cell.column.columnDef;
              return (
                <Td key={cell.id} isNumeric={meta?.isNumeric}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
